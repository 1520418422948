<template>
    <div class="back" @click="back">
        <svg version="1.0" width="1280.000000pt" height="1247.000000pt" viewBox="0 0 1280.000000 1247.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0,1247) scale(0.1,-0.1)" fill="rgb(91,97,120)" stroke="none">
                <path d="M7556 10480 c-384 -61 -686 -316 -799 -676 -88 -278 -46 -583 115
                -832 40 -62 200 -228 806 -834 l756 -758 -3289 -3 -3290 -2 -83 -23 c-412
                -110 -696 -437 -742 -850 -50 -453 224 -878 662 -1028 166 -57 -15 -54 3503
                -54 l3240 0 -756 -757 c-470 -471 -772 -781 -796 -818 -266 -399 -218 -911
                117 -1245 186 -187 417 -282 685 -283 169 -1 295 28 445 103 168 85 66 -15
                2024 1955 l1441 1450 52 95 c196 364 159 787 -98 1104 -32 39 -772 785 -1646
                1657 -1369 1367 -1600 1594 -1674 1643 -157 102 -315 153 -504 160 -60 3 -137
                1 -169 -4z"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name:'backBtn',
    methods:{
        back(){
            this.$router.back(-1);
        }
    },
}
</script>

<style scoped>
.back{
    width: 80px;
    height: 80%;
    margin-left: 4vw;
    font-size: 2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 10%);
    border-radius: 1vw;
    background-color: #14162b70;
}
svg{
    transform: rotateZ(180deg) scale(.5);
}
</style>